<script setup>
import { nextTick, onBeforeMount, inject } from "vue";
import analytics from "@/js/analytics";
// import userimage from "/public/assets/imgs/digital-vendor.png";
const props = defineProps({
  title: { default: "", type: String },
  img: { default: "", type: String },
  icons: { default: [], type: Array },
  toggleModal: { default: () => {}, type: Function },
  setModalContent: { default: () => {}, type: Function },
  iconTitle: { default: [], type: Array },
  iconLabel: { default: "", type: String },
  selected: { default: false, type: Boolean },
  analyticTags: { default: [], type: Array },
});
const translate = inject("translate");

const generateImg = () => {
  if (props.title === "optimization") {
    return;
  }
};
const handleClick = (item, inc, index) => {
  analytics.googleAnalytics(props.title, props.analyticTags[index]);

  props.setModalContent(`${item}-${inc}`);
  props.toggleModal();
};
</script>

<template>
  <div :class="`red-circle red-circle-${props.title}`">
    <div class="small-wrapper">
      <div class="small-circles">
        <div class="top-icons" v-if="!selected">
          <div
            :class="`${props.title}-1`"
            @click="
              () => {
                handleClick(props.title, 1, 0);
              }
            "
            :aria-label="props.iconTitle[0]"
          >
            <img :src="props.icons[0]" />
            <span class="icon-label" v-html="translate(props.iconTitle[0])" />
          </div>
        </div>
        <div class="bottom-icons">
          <div
            :class="`${props.title}-2`"
            @click="
              () => {
                handleClick(props.title, 2, 1);
              }
            "
            :aria-label="props.iconTitle[1]"
          >
            <img :src="props.icons[1]" />
            <span class="icon-label" v-html="translate(props.iconTitle[1])" />
          </div>
          <div
            :class="`${props.title}-3`"
            @click="
              () => {
                handleClick(props.title, 3, 2);
              }
            "
            :aria-label="props.iconTitle[2]"
          >
            <img :src="props.icons[2]" />
            <span class="icon-label" v-html="translate(props.iconTitle[2])" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../sass/GlobalClasses";
@import "../sass/Settings";
.red-circle {
  position: relative;
  height: vh(950);
  width: vw(950);
  background-size: cover !important;
  background-position: center;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  pointer-events: none;
  .title-wrapper {
    img {
      height: 100%;
      width: 100%;
    }
    span {
      font-size: 2.5rem;
      color: darken(white, 8%);
    }
  }
  .title-wrapper-innovation {
    background: url("~/public/assets/imgs/innovation-header.png");
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    position: relative;
    left: vw(20);
  }
  .title-wrapper-optimization {
    background: url("~/public/assets/imgs/optimization-header.png");
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    position: relative;
    left: vw(-20);
  }
  .title-wrapper-insights {
    background: url("~/public/assets/imgs/insights-header.png");
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    position: relative;
    left: vw(20);
  }
  .small-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    min-height: vh(200);
    .top-circle-wrapper {
      min-height: vh(100);
      min-width: vw(100);
    }

    .small-circles {
      border-radius: 50%;
      min-height: vh(100);
      height: auto;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .top-icons {
        margin-right: vw(0);
      }
      .top-icons,
      .bottom-icons {
        height: 100%;
        max-width: vw(205);
        position: relative;
        left: vw(-35);
        div {
          width: fit-content;
        }
        img {
          height: 100%;
          width: 85%;
          max-width: 175px;
        }
        div {
          &:last-of-type {
            margin-top: vh(20);
          }
        }
        span {
          font-family: tc-reg;
          font-size: vw(18);
          font-stretch: normal;
          font-style: normal;
          line-height: 1.05;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          opacity: 0;
          transition: opacity 0.35s ease;
          width: 100%;
          &.active {
            opacity: 1;
          }
        }
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
.red-circle-optimization {
  background: url("~/public/assets/imgs/circle-optimization.png");
  z-index: 6;
  .small-wrapper {
    transform: translate(0%, 0%) rotate(-15deg);
    left: vw(90);

    .small-circles {
      position: relative;

      flex-direction: row-reverse;
      top: vh(25);
      right: vw(-110);
      transition: transform 0.35s linear;
      &.active {
        transform: translateX(vw(-35));
      }
      .top-icons {
        margin-right: 0;
        margin-left: vw(0);
      }
      .top-icons,
      .bottom-icons {
        // right: -35px;
        left: 0;
      }
    }
  }
}
.red-circle-insights {
  background: url("~/public/assets/imgs/circle-insights.png");
  z-index: 4;
  display: block;
  .small-wrapper {
    .small-circles {
      position: relative;
      right: vw(18);
    }
  }
}
.red-circle-innovation {
  background: url("~/public/assets/imgs/circle-innovation.png");
  z-index: 5;
  display: block;

  .small-wrapper {
    .small-circles {
      position: relative;
      right: vw(18);
    }
  }
}
</style>
