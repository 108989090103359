<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import LandingView from "./views/LandingWrapper.vue";
import ScreenSaver from "./views/ScreenSaver.vue";
import analytics from "./js/analytics";
// grab state
const isLaunched = ref(false);
const isFlashing = ref(false);
const time = ref(Date.now());
const homeTime = ref(0);

const setLaunched = (bool) => {
  isLaunched.value = bool;
  if (bool === false) {
    const flash = document.querySelector(".camera-flash");
    const circles = Array.from(document.querySelectorAll(".red-circle"));

    circles.map((circle) => {
      circle.classList.add("active");

      circle.style.transition = "all 1s ease";
      circle.style.pointerEvents = "none";
    });

    flash.classList.remove("fade-out");
    flash.classList.remove("active");
  }
};
const toggleFlash = async () => {
  analytics.googleAnalytics("intro", "tap");
  analytics.dwellTimeOnPageEvent("intro", time.value);

  isFlashing.value = true;
  const circles = Array.from(document.querySelectorAll(".red-circle"));

  const flash = document.querySelector(".camera-flash");
  flash.classList.add("active");

  setTimeout(() => {
    flash.classList.add("fade-out");

    isFlashing.value = false;
    setLaunched(true);

    setTimeout(async () => {
      await animateCircles();

      setTimeout(() => {
        circles.map((circle) => {
          circle.style.transition = "unset";
          circle.style.pointerEvents = "all";
          homeTime.value = Date.now();
        });
      }, 1000);
    }, 250);
  }, 1000);
};
const animateCircles = async () => {
  const circles = Array.from(document.querySelectorAll(".red-circle"));

  return new Promise((resolve) => {
    circles.map((circle, i) => {
      circle.classList.remove("active");
    });
    resolve();
  });
};
const setHomeTime = (value) => {
  homeTime.value = value;
};
</script>

<template>
  <div>
    <ScreenSaver
      v-if="!isLaunched"
      :toggleFlash="toggleFlash"
      :isLaunched="isLaunched"
      :isFlashing="isFlashing"
    />
    <transition name="slide-fade">
      <div class="camera-flash" />
    </transition>

    <transition name="slide-fade">
      <LandingView
        :setLaunched="setLaunched"
        :homeTime="homeTime"
        :setHomeTime="setHomeTime"
      />
    </transition>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
.flash {
  background: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  pointer-events: none;
  display: block;
  width: 100%;
  height: 100svh;
  opacity: 0;
  transition: opacity 1s ease;
}
</style>
