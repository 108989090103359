<script setup>
import { onMounted, ref } from "vue";
import RedCircle from "../components/RedCircle.vue";
import FSModal from "../components/FSModal.vue";

import digitalVendor from "/public/assets/imgs/digital-vendor.png";
import emergingTech from "/public/assets/imgs/emerging-tech.png";
import digitalData from "/public/assets/imgs/digital-data.png";
import primaryResearch from "/public/assets/imgs/primary-research.png";
import homeTap from "/public/assets/imgs/home-tap.png";
import closeBtnTap from "/public/assets/imgs/close-btn-tap.png";
import closeBtn from "/public/assets/imgs/close-btn.png";

import homeReg from "/public/assets/imgs/home.png";
import loyalty from "/public/assets/imgs/loyalty.png";
import digitalCategory from "/public/assets/imgs/digital-category.png";
import digitalOrdering from "/public/assets/imgs/digital-ordering.png";
import acquisition from "/public/assets/imgs/acquisition.png";
import partnership from "/public/assets/imgs/partnership.png";
import gsap from "gsap";
import analytics from "@/js/analytics";
const props = defineProps({
  setLaunched: { default: false, type: Boolean },
  setHomeTime: { default: () => {}, type: Function },

  homeTime: { default: 0, type: Date },
});
// sets user activity timer threshold
const timerLimit = ref(30);
const selected = ref(false);
const check = ref(false);
const modalData = ref({});
const selectedCircle = ref(false);
const modalOpen = ref(false);
const currentOpt = ref(false);
const currSeconds = ref(0);
const timer = ref(0);
const circleTimer = ref(0);
const toggleModal = () => {
  const modal = document.querySelector(".fs-modal");
  const closeIcon = document.querySelector(".close-btn img");

  if (modalOpen.value) {
    const faded = document.querySelector(`.faded`);

    faded.classList.remove("faded");

    closeIcon.src = closeBtnTap;

    setTimeout(() => {
      modalOpen.value = false;
      if (modal) modal.classList.remove("active");

      closeIcon.src = closeBtn;
    }, 100);
  } else {
    if (!selected.value) return;
    let icon;
    icon = document.querySelector(`.${currentOpt.value}`);
    icon.classList.add("faded");

    setTimeout(() => {
      modalOpen.value = true;
      modal.classList.add("active");
    }, 100);
  }
};
const setModalContent = (option) => {
  const modal = document.querySelector(".fs-modal");
  const icon = document.querySelector(`.${option}`);

  if (option === "innovation-1") {
    currentOpt.value = option;
    modalData.value = {
      icon: emergingTech,
      iconTitle: "innovation-modal-1",
      content: "innovation-1-content",
      img: "",
    };
  } else if (option === "innovation-2") {
    currentOpt.value = option;
    modalData.value = {
      icon: digitalVendor,
      iconTitle: "innovation-modal-2",
      content: "innovation-2-content",
      img: "",
    };
  } else if (option === "innovation-3") {
    currentOpt.value = option;
    modalData.value = {
      icon: digitalData,
      iconTitle: "innovation-modal-3",
      content: "innovation-3-content",
      img: "",
    };
  } else if (option === "insights-1") {
    currentOpt.value = option;
    modalData.value = {
      icon: loyalty,
      iconTitle: "insights-modal-1",
      content: "insights-1-content",
      img: "",
    };
  } else if (option === "insights-2") {
    currentOpt.value = option;
    modalData.value = {
      icon: primaryResearch,
      iconTitle: "insights-modal-2",
      content: "insights-2-content",
      img: "",
    };
  } else if (option === "insights-3") {
    currentOpt.value = option;
    modalData.value = {
      icon: digitalCategory,
      iconTitle: "insights-modal-3",
      content: "insights-3-content",
      img: "",
    };
  } else if (option === "optimization-1") {
    currentOpt.value = option;
    modalData.value = {
      icon: acquisition,
      iconTitle: "optimization-modal-1",
      content: "optimization-1-content",
      img: "",
    };
  } else if (option === "optimization-2") {
    currentOpt.value = option;
    modalData.value = {
      icon: digitalOrdering,
      iconTitle: "optimization-modal-2",
      content: "optimization-2-content",
      img: "",
    };
  } else if (option === "optimization-3") {
    currentOpt.value = option;
    modalData.value = {
      icon: partnership,
      iconTitle: "optimization-modal-3",
      content: "optimization-3-content",
      img: "",
    };
  }
};
// controls position of selected & inactive circles by using GSAP
const selectCircle = (key) => {
  let currentCircle;
  if (!check.value) {
    analytics.dwellTimeOnPageEvent("home", props.homeTime);
    props.setHomeTime(0);
    check.value = true;
  }
  const circ = document.querySelector(
    ".red-circle-optimization .small-circles"
  );
  let icons = Array.from(document.querySelectorAll(".small-circles"));
  icons.map((icon) => icon.classList.add("hidden"));

  const allLabels = Array.from(document.querySelectorAll(".icon-label"));
  allLabels.map((label) => label.classList.remove("active"));
  if (key === "innovation") {
    if (selectedCircle.value !== "innovation")
      analytics.googleAnalytics("home", "tap", "innovation");

    currentCircle = document.querySelector(".red-circle-innovation");

    circleTimer.value = Date.now();
    const labels = Array.from(
      document.querySelectorAll(`.red-circle-${key} .icon-label`)
    );
    labels.map((label) => label.classList.add("active"));

    const selectIcons = Array.from(
      document.querySelectorAll(`.red-circle-${key} .small-circles`)
    );
    selectIcons.map((icon) => icon.classList.remove("hidden"));

    selectedCircle.value = key;
    gsap.to(currentCircle, {
      yPercent: 45.5,
      xPercent: -11,
      duration: 1.0,
      scale: 1.35,
      rotate: 0,
      onComplete: () => {
        selected.value = true;
      },
    });
    gsap.to(".red-circle-optimization", {
      yPercent: 35,
      xPercent: -3,
      duration: 1.0,
      scale: 0.5,
      rotate: 0,
    });
    gsap.to(".red-circle-insights", {
      yPercent: 38,
      xPercent: 10,
      rotate: -25,
      duration: 1.0,
      scale: 0.5,
    });
    if (circ.classList.contains("active")) circ.classList.remove("active");
  } else if (key === "optimization") {
    currentCircle = document.querySelector(`.red-circle-${key}`);
    if (selectedCircle.value !== "optimization")
      analytics.googleAnalytics("home", "tap", "optimization");
    circleTimer.value = Date.now();

    circ.classList.add("active");

    const labels = Array.from(
      document.querySelectorAll(`.red-circle-${key} .icon-label`)
    );
    labels.map((label) => label.classList.add("active"));
    const selectIcons = Array.from(
      document.querySelectorAll(`.red-circle-${key} .small-circles`)
    );
    selectIcons.map((icon) => icon.classList.remove("hidden"));
    selectedCircle.value = key;
    gsap.to(currentCircle, {
      yPercent: -50,
      xPercent: 19,
      duration: 1.0,
      scale: 1.35,
      rotate: 15,
      onComplete: () => {
        selected.value = true;
      },
    });
    gsap.to(".red-circle-innovation", {
      yPercent: -37,
      xPercent: 10,
      rotate: -67,
      duration: 1.0,
      scale: 0.5,
    });
    gsap.to(".red-circle-insights", {
      yPercent: 38,
      xPercent: 10,
      rotate: -25,
      duration: 1.0,
      scale: 0.5,
    });
  } else if (key === "insights") {
    if (circ.classList.contains("active")) circ.classList.remove("active");
    currentCircle = document.querySelector(`.red-circle-${key}`);

    if (selectedCircle.value !== "insights")
      analytics.googleAnalytics("home", "tap", "insights");
    circleTimer.value = Date.now();

    const labels = Array.from(
      document.querySelectorAll(`.red-circle-${key} .icon-label`)
    );
    labels.map((label) => label.classList.add("active"));
    const selectIcons = Array.from(
      document.querySelectorAll(`.red-circle-${key} .small-circles`)
    );
    selectIcons.map((icon) => icon.classList.remove("hidden"));
    selectedCircle.value = key;
    analytics.googleAnalytics(key, "tap");

    gsap.to(currentCircle, {
      yPercent: -50,
      xPercent: -11,
      duration: 1.0,
      scale: 1.35,
      rotate: 0,
      onComplete: () => {
        selected.value = true;
      },
    });
    gsap.to(".red-circle-innovation", {
      yPercent: -37,
      xPercent: 10,
      rotate: -67,
      duration: 1.0,
      scale: 0.5,
    });
    gsap.to(".red-circle-optimization", {
      yPercent: 35,
      xPercent: -3,
      duration: 1.0,
      scale: 0.5,
      rotate: 0,
    });
  } else {
    reset();
  }
};
const reset = () => {
  check.value = false;
  const modal = document.querySelector(".fs-modal");
  const homeIcon = document.querySelector(".home-icon");
  const circ = document.querySelector(
    ".red-circle-optimization .small-circles"
  );
  let icons = Array.from(document.querySelectorAll(".small-circles"));

  currentOpt.value = false;
  modal.classList.remove("active");

  if (circ.classList.contains("active")) circ.classList.remove("active");
  if (homeIcon) {
    setTimeout(() => {
      homeIcon.src = homeReg;
    }, 100);
    homeIcon.src = homeTap;
  }

  if (icons) {
    icons.map((icon) => icon.classList.add("hidden"));
  }

  const allLabels = Array.from(document.querySelectorAll(".icon-label"));
  allLabels.map((label) => label.classList.remove("active"));

  if (modalOpen.value) toggleModal();

  selectedCircle.value = false;
  modalData.value = {};

  icons.map((icon) => icon.classList.remove("hidden"));

  gsap.to(".red-circle-insights", {
    yPercent: 0,
    xPercent: 0,
    rotate: 0,
    duration: 1.0,
    scale: 1.0,
    onComplete: () => {
      selected.value = false;
    },
  });
  gsap.to(".red-circle-optimization", {
    yPercent: -50,
    xPercent: 0,
    duration: 1.0,
    scale: 1.0,
    rotate: 15,
    onComplete: () => {
      selected.value = false;
    },
  });
  gsap.to(".red-circle-innovation", {
    yPercent: 0,
    xPercent: 0,
    rotate: 0,
    duration: 1.0,
    scale: 1.0,
    onComplete: () => {
      selected.value = false;
    },
  });
};
const resetHomeTime = () => {
  props.setHomeTime(Date.now());
  if (circleTimer.value !== 0) {
    analytics.dwellTimeOnPageEvent(selectedCircle.value, circleTimer.value);
    circleTimer.value = 0;
  }
};

const resetTimer = () => {
  /* Clear the previous interval */
  clearInterval(timer.value);

  /* Reset the seconds of the timer */
  currSeconds.value = 0;

  /* Set a new interval */
  timer.value = setInterval(startIdleTimer, 1000);
};
const startIdleTimer = () => {
  currSeconds.value++;

  if (currSeconds.value >= timerLimit.value) {
    resetTimer();
    props.setLaunched(false);
    reset();
    analytics.dwellTimeOnPageEvent("home", props.homeTime);
    props.setHomeTime(0);
  } else {
  }
};
onMounted(() => {
  const container = document.querySelector(".landing-page");
  window.addEventListener("click", resetTimer, this);
  window.addEventListener("touchstart", resetTimer, this);
  container.onload = resetTimer;
});
</script>

<template>
  <div class="landing-page">
    <div class="banner-wrapper">
      <div class="banner">
        <img src="../../public/assets/imgs/coke-banner.png" />
      </div>
      <div
        class="banner-home"
        v-if="selectedCircle && !modalOpen"
        @click="
          () => {
            resetHomeTime();
            reset();
          }
        "
      >
        <img class="home-icon" src="../../public/assets/imgs/home.png" />
      </div>
    </div>
    <div class="circle-wrapper">
      <RedCircle
        class="active"
        :title="'innovation'"
        :toggleModal="toggleModal"
        :setModalContent="setModalContent"
        @click="selectCircle('innovation')"
        :icons="[emergingTech, digitalVendor, digitalData]"
        :selectedCircle="selectedCircle"
        :iconTitle="['innovation-1', 'innovation-2', 'innovation-3']"
        :analyticTags="[
          'innovation_emerging-tech_tap',
          'innovation_digital-vendor_tap',
          'innovation_data-enablement_tap',
        ]"
      />
      <RedCircle
        class="active"
        :iconLabels="['experimentation', 'vendor-landscape', 'enablement']"
        :title="'insights'"
        :setModalContent="setModalContent"
        @Click="selectCircle('insights')"
        :toggleModal="toggleModal"
        :icons="[loyalty, primaryResearch, digitalCategory]"
        :selectedCircle="selectedCircle"
        :iconTitle="['insights-1', 'insights-2', 'insights-3']"
        :analyticTags="[
          'insights_loyalty_tap',
          'insights_research_tap',
          'insights_digital-category_tap',
        ]"
      />
      <RedCircle
        class="active"
        :title="'optimization'"
        @Click="selectCircle('optimization')"
        :toggleModal="toggleModal"
        :setModalContent="setModalContent"
        :icons="[acquisition, digitalOrdering, partnership]"
        :selectedCircle="selectedCircle"
        :iconTitle="['optimization-1', 'optimization-2', 'optimization-3']"
        :analyticTags="[
          'optimization_acquisition_tap',
          'optimization_digital-ordering_tap',
          'optimization_3pd-fsa_tap',
        ]"
      />
    </div>
    <transition name="slide-fade">
      <div>
        <FSModal
          :iconTitle="modalData.iconTitle"
          :icon="modalData.icon"
          :content="modalData.content"
          :qrImg="modalData.qrImg"
          :img="modalData.img"
          :toggleModal="toggleModal"
        /></div
    ></transition>
  </div>
</template>

<style scoped lang="scss">
@import "../sass/GlobalClasses";
@import "../sass/Settings";

.landing-page {
  background: white;
  height: 100svh;

  background: url("~/public/assets/imgs/background-main.png");
  background-size: cover;
  background-position: center;
  transition: all 1s ease;
  position: relative;
  display: block;
  z-index: 14;
  // transform: scale(0);
  // transition: transform 1s ease;
  .banner-wrapper {
    position: absolute;
    top: vh(44);
    left: vw(44);
    z-index: 13;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner {
      height: vh(225);
      width: vw(225);
      margin-right: vw(20);
      img {
        height: 100%;
        width: 100%;
      }
    }
    .banner-home {
      height: vh(84);
      width: vw(84);
      position: relative;
      top: vh(-40);
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .circle-wrapper {
    height: 100%;
    position: relative;
    .red-circle-optimization {
      .small-wrapper {
        transform: rotate(-15deg);
        .small-circles {
          flex-direction: row-reverse;
        }
      }
    }
    .red-circle {
      transition: all 1s ease;
      .title-wrapper {
      }
      &:first-of-type {
        position: absolute;
        right: vw(-319) !important;
        top: vh(-115) !important;
        &.active {
          transform: translate(10%, -37%) scale(0.5) rotate(-70deg) !important;
        }
      }
      &:nth-of-type(2) {
        position: absolute;
        right: vw(-319);
        bottom: vh(-144);
        // transform: rotate(-5deg);
        &.active {
          transform: translate(10%, 35%) scale(0.5) rotate(-25deg) !important;
        }
      }
      &:last-of-type {
        position: absolute;
        left: vw(-408);
        top: 50%;
        transform: translateY(-50%) rotate(15deg);
        &.active {
          transform: translate(-3%, 35%) scale(0.5) !important;
        }
      }
    }
  }
  .title-image {
    position: relative;
    margin-top: vh(24);
  }

  .title-text-0 {
    font-weight: bold;
    color: $main-text;
    font-size: 28px;
    line-height: 1.07;
    letter-spacing: 1.4px;
    margin-bottom: vh(9);
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  }

  .orange-line {
    position: relative;
    width: vh(48);
    height: vh(1);
    background-color: $trigger-orange;
    margin-bottom: vh(11);
  }

  .title-text-1 {
    font-weight: 500;
    color: $sub-text;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.14px;
    margin-bottom: vh(33);
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  }

  .continue-button {
    position: relative;
    width: vh(170);
    height: vh(55);
    border: $button-outline solid 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: vh(40);
    pointer-events: auto;

    .text {
      font-size: 16px;
      font-weight: bold;
      line-height: 3.13;
      letter-spacing: 1.2px;
      color: $main-text;
    }
  }
}
</style>
