const analytics = {
  currentPage: "",
  timeStartedPage: 0,

  timers: {},

  //NOTE: You cannot set the method here, you have to set it in init
  analyticsMethod: null,

  dataLayerDefault: {},

  init() {
    this.startTimer("OverallDwellTime");
    console.log("init analytics");
    window.addEventListener("focus", this.focusedPage.bind(this));
    window.addEventListener("blur", this.blurPage.bind(this));

    //Change this.dataLayerAnalytics to whatever analytics method the project is using
    this.analyticsMethod = this.googleAnalytics;
  },
  setupEvents() {
    let func = function (category, action) {
      this.analyticsMethod(category, action);
    };
    AR.app.off("analytics-event");
    AR.app.on("analytics-event", func, this);
  },

  focusedPage() {
    this.startTimer("OverallDwellTime");
    this.timeStartedPage = Date.now();
  },
  blurPage() {
    if (this.currentPage !== "")
      this.dwellTimeOnPageEvent(this.currentPage, this.timeStartedPage);
    this.sendEventForTimer("dwell-time", "overall", "OverallDwellTime");
  },

  //Analytics Method 1:
  googleAnalytics(category, action, label, value) {
    let eventName;
    if (category == action) eventName = category;
    else eventName = action + "-" + category;

    if (label != null && value != null) {
      gtag("event", eventName, { label: label, value: value });
      console.log(
        "analytics event - google analytics: " +
          category +
          ", " +
          action +
          ", " +
          label +
          ", " +
          value
      );
    } else if (value != null) {
      gtag("event", eventName, { value: value });
      console.log(
        "analytics event - google analytics: " +
          category +
          ", " +
          action +
          ", " +
          value
      );
    } else if (label != null) {
      gtag("event", eventName, { label: label });
      console.log(
        "analytics event - google analytics: " +
          category +
          ", " +
          action +
          ", " +
          label
      );
    } else {
      gtag("event", eventName);
      console.log(
        "analytics event - google analytics: " + category + ", " + action
      );
    }
  },

  //Analytics Method 2:
  dataLayerAnalytics(category, action, label, value) {
    console.log(
      "analytics event - data layer: " +
        category +
        ", " +
        action +
        ", " +
        label +
        ", " +
        value
    );
    let event = {};
    event[category] = {
      action: action,
      label: label,
      value: value,
    };

    window.dataLayer = [];
    window.dataLayer.push(this.dataLayerDefault);
    window.dataLayer.push(event);
  },

  pageVisitEvent(pageId) {
    this.analyticsMethod("page-visit", pageId);
  },

  dwellTimeOnPageEvent(pageId, startTime) {
    let dwellOnLastPage = (Date.now() - startTime) / 1000;
    this.googleAnalytics("dwell-time", pageId, null, dwellOnLastPage);
  },

  changePage(pageId) {
    if (pageId !== this.currentPage) {
      //Log the dwell time on the last page.
      if (this.timeStartedPage !== 0)
        this.dwellTimeOnPageEvent(this.currentPage, this.timeStartedPage);

      this.currentPage = pageId;
      this.pageVisitEvent(pageId);
      this.timeStartedPage = Date.now();
    }
  },

  startTimer(timerId) {
    this.timers[timerId] = Date.now();
  },
  sendEventForTimer(category, action, timerId) {
    if (this.timers[timerId] != null) {
      let time = (Date.now() - this.timers[timerId]) / 1000;
      this.analyticsMethod(category, action, null, time);
    } else {
      console.log(
        "Tried to send an event for timer " +
          timerId +
          " which hasn't been started."
      );
    }
  },
};

export default analytics;
