<script setup>
import { nextTick, onBeforeMount, inject } from "vue";

const props = defineProps({
  modalData: { default: {}, type: Object },
  iconTitle: { default: "", type: String },
  content: { default: "", type: String },
  img: { default: "", type: String },
  icon: { default: "", type: String },
  toggleModal: { default: () => {}, type: Function },
});
const translate = inject("translate");
</script>

<template>
  <div class="fs-modal">
    <div class="close-wrapper">
      <div class="close-btn" @click="toggleModal()">
        <img src="../../public/assets/imgs/close-btn.png" />
      </div>
    </div>
    <div class="content-wrapper">
      <div class="icon">
        <img :src="icon" />
      </div>

      <div class="title">
        <span v-html="translate(iconTitle)" />
      </div>
      <div class="content"><span v-html="translate(content)" /></div>
    </div>

    <!-- <div>
      <img :src="img" />
    </div> -->
  </div>
</template>

<style scoped lang="scss">
@import "../sass/GlobalClasses";
@import "../sass/Settings";
.fs-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100svh;
  width: 100%;
  background: rgb(216, 46, 40);
  background: linear-gradient(
    180deg,
    rgba(216, 46, 40, 1) 0%,
    rgba(156, 31, 36, 1) 83%
  );
  color: white;
  z-index: 11;
  background: url("~/public/assets/imgs/background-second.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: vh(167);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  .close-wrapper {
    position: absolute;
    top: vh(43);
    right: vw(43);
    .close-btn {
      height: vh(112);
      width: vw(112);
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: vw(730);
    .icon {
      height: vh(200);
      width: vw(200);
      img {
        height: 100%;
        width: 100%;
      }
    }
    .title {
      margin-top: vh(10);
      width: 100%;
      span {
        font-family: tc-black;
        font-size: vw(40);
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }

    .content {
      width: 100%;
      margin: vh(40) auto vh(80) auto;
      text-align: left;
      span {
        font-family: tc-reg;
        font-size: vw(26);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: vh(40);
        letter-spacing: normal;
        color: #fff;
      }
    }
  }
  .qr-code-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .qr-code {
      height: vh(115);
      width: vw(115);
      img {
        height: 100%;
        width: 100%;
      }
    }

    span {
      text-align: left;
      margin-left: vw(26);
      font-family: tc-reg;
      font-size: vw(22);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }
}
</style>
